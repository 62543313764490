<template>
    <div>
        <loadScreenComponent :is-loading="isLoading"></loadScreenComponent>
        <page-header page-title="Assinaturas" :allotment-index="allotmentId"
                     @selectAllotment="alterAllotment" :withNoneAllotment="true"
                     :showAllotment="true"
        ></page-header>
        <div class="card p-3">
            <div class="row gutters">
                <h4 class="col-12"><i class="icon-layers2"></i> Documentos {{ getAllomentName() }}</h4>
                <div class="col-12 p-3 d-flex" style="gap: 12px"
                     :class="!widthMd(690) ? '' : 'flex-wrap'">
                    <div class="d-flex flex-column p-3 flex-grow-1"
                           :class="!widthMd(690) ?'col-6' : 'col-12'"
                         style=" background: linear-gradient(90deg,#dcefe9 40%,#b2e3d0 100%); border-radius: 15px; ">
                        <h4 class="font-weight-bold">Neste Momento</h4>
                        <div class="row gutters mt-4 p-1">
                            <div class="col-6 pointer" style="color: #092f6b"
                                 @click="$router.push({name: 'IndexAssignPending', params:{allotmentId: allotmentId}})">
                                <h2 class="font-weight-bold pl-1">{{ resumAssign?.awaiting?.total }}</h2>
                                <h6 class="mt-1"><i class="icon-schedule"></i> Aguardando</h6>
                            </div>
                            <div class="col-6 pointer" style="color: #79430d"
                                 @click="$router.push({name: 'IndexAssignPendingAdm', params:{allotmentId: allotmentId}})">
                                <h2 class="font-weight-bold pl-1">{{ resumPendingAdm['document-pending']?.total }}</h2>
                                <h6 class="mt-1"><i class="icon-recent_actors"></i> Pendente P/ Administração</h6>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column p-3 flex-grow-1"
                         :class="!widthMd(690) ?'col-6' : 'col-12'"
                         style="background: linear-gradient(90deg,#d5efef 40%,#a8c9cb 100%); border-radius: 15px ">
                        <select2 :settings="{width: !widthMd(690) ? '40%' : '80%'}" :options="[
                                {id: '7', text: 'Últimos 07 Dias'},
                                {id: '15', text: 'Últimos 15 Dias'},
                                {id: '30', text: 'Últimos 30 Dias'},
                                {id: '60', text: 'Últimos 60 Dias'},
                                {id: '90', text: 'Últimos 90 Dias'},
                                {id: '120', text: 'Últimos 120 Dias'},
                                ]" v-model="daysFilter" @select="getResum"></select2>
                        <div class="row gutters mt-4 p-1">
                            <div class="col-4 pointer" style="color: #2e644a" @click="$router.push({name: 'IndexAssignFinalized', params:{
                                days:daysFilter,
                            allotmentId: allotmentId}
                            })">
                                <h2 class="font-weight-bold pl-1">{{ resumAssign?.signed?.total }}</h2>
                                <h6 class="mt-1"><i class="icon-check"></i> Finalizadas</h6>
                            </div>
                            <div class="col-4 pointer" style="color: #584d07"
                                 @click="$router.push({name: 'IndexAssignExpired', params:{days:daysFilter, allotmentId: allotmentId}})">
                                <h2 class="font-weight-bold pl-1">{{ resumAssign?.expired?.total }}</h2>
                                <h6 class="mt-1"><i class="icon-cached"></i> Expiradas</h6>
                            </div>
                            <div class="col-4 pointer" style="color: #9d1c1c"
                                 @click="$router.push({name: 'IndexAssignCanceled', params:{days:daysFilter, allotmentId: allotmentId}})">
                                <h2 class="font-weight-bold pl-1">{{ resumAssign?.canceled?.total }}</h2>
                                <h6 class="mt-1"><i class="icon-cancel"></i> Canceladas</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-end pointer" @click="$router.push({name: 'IndexAssignAll', params:{allotmentId: allotmentId}})">
                    <h5> Ver todas Assinaturas</h5></div>
            </div>
        </div>
        <div class="card">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="pt-3 row">
                    <div class="col-12 d-flex flex-row justify-content-center">
                        <h5 class="card-title">{{
                                this.t('ASSIGN.RESUM_ASSIGN', {allotmentName: getAllomentName()})
                            }}</h5>
                    </div>
                    <div class="main-container col-12" style="height: 40vh">
                        <div id="chartAssigns"></div>
                    </div>
                </div>
            </div>
            <div class=" col-12 d-flex justify-content-center w-100 pb-3">
                <div class="btn btn-primary mr-2" @click="alterQuantityGrafic(true)"
                     :class="grapicDay ?'active' :''"><i v-if="grapicDay" class="icon-check-circle"></i>
                    Últimos 30 Dias
                </div>
                <div class="btn btn-primary " :class="grapicDay ?'' :'active'"
                     @click="alterQuantityGrafic(false)"><i v-if="!grapicDay"
                                                            class="icon-check-circle"></i>
                    Últimos 12 Meses
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from '../../components/layouts/pageHeader';
import ApexCharts from 'apexcharts';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Select2 from 'vue3-select2-component';
import Proposal from '../../services/Proposal';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import {mapState} from 'vuex';
import permission from '../../components/permission';

export default {
    name: "dashboardAssignComponent",
    components: {
        pageHeader,
        Select2,
        loadScreenComponent
    },
    mixins:[permission],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    beforeRouteLeave(to, from, next) {
        this.chartGraphicAssign?.destroy();
        return next();
    },
    computed: {
        ...mapState({
            isLoading: 'isLoading',
            allotmentsStore: 'allotments',
            hasConfigClickSign: 'hasConfigClickSign'
        })
    },
    data() {
        return {
            allotmentId: null,
            chartGraphicAssign: null,
            daysFilter: '7',
            grapicDay: true,
            resumAssign: {},
            resumPendingAdm: {},
        }
    },
    mounted() {
        if (!this.validatePermissions('ConfigClickSign:index')) {
            this.$router.push({name: 'Home'});
            return;
        }
        this.getResum();
        this.getProgres();
        this.getResumPendingAdm();
    },
    methods: {
        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },
        getAllomentName() {
            if (!this.allotmentId) return '';
            let name = this.allotmentsStore?.find(i => i.id === this.allotmentId)?.text;
            if (!name) return '';
            return `- ${name}`;
        },
        alterAllotment(id) {
            if (id === 'selecione') {
                this.allotmentId = null;
            } else {
                this.allotmentId = id;
            }
            this.getResum();
            this.getProgres();
            this.getResumPendingAdm();
        },
        alterQuantityGrafic(boll) {
            this.grapicDay = boll;
            this.getProgres();
        },
        getResumPendingAdm() {
            this.$store.commit('changeLoading', true);
            Proposal.getResumPendingAdm(this.allotmentId).then(resp => {
                this.$store.commit('changeLoading', false);
                this.resumPendingAdm = resp.data;
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        getProgres() {
            this.$store.commit('changeLoading', true);
            Proposal.getProgressAssigns(this.grapicDay, this.allotmentId).then(resp => {
                let result = resp.data
                let seriesReserve = [];

                seriesReserve.push({
                    name: result.name1,
                    data: result.assinaturas

                });

                seriesReserve.push({
                    name: result.name2,
                    data: result.assinados
                });

                seriesReserve.push({
                    name: 'Aguardando',
                    data: result.aguardando
                });

                seriesReserve.push({
                    name: result.name4,
                    data: result.cancelados
                });
                seriesReserve.push({
                    name: result.name5,
                    data: result.expirados
                });

                let chartOptionsReserve = {
                    chart: {
                        height: '100%',
                        type: 'area',
                        width: '100%',
                        defaultLocale: 'pt',
                        locales: [{
                            name: 'pt',
                            options: {
                                toolbar: {
                                    download: 'Download SVG',
                                    selection: 'Selecionar',
                                    selectionZoom: 'Selecionar Zoom',
                                    zoomIn: 'Aumentar Zoom',
                                    zoomOut: 'Reduzir Zoom',
                                    pan: 'Panorama',
                                    reset: 'Resetar Zoom',
                                }
                            }
                        }]
                    },
                    responsive: [
                        {
                            breakpoint: 1000,
                            options: {
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ],
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },
                    series: seriesReserve,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },

                    colors: ['#36848d', '#08bc91', '#005d8d', '#334954', '#001e3b'],
                }
                this.plotChart(chartOptionsReserve, 'chartAssigns');
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        plotChart(chartOptionsReserve, id) {
            let elChartReserve = document.getElementById(id);
            this.chartGraphicAssign?.destroy();
            this.chartGraphicAssign = new ApexCharts(elChartReserve, chartOptionsReserve);
            this.chartGraphicAssign.render();
            this.$store.commit('changeLoading', false);
        },
        getResum() {
            this.$store.commit('changeLoading', true);
            Proposal.getResumAssigns(this.daysFilter, this.allotmentId).then(resp => {
                this.$store.commit('changeLoading', false);
                this.resumAssign = resp.data;
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        }
    }
}
</script>

<style scoped>

</style>