import {http} from './config';

export default {
    indexTemplate: (filter, page = 1) => {
        return http.get('api/v1/modelos?page=' + page);
    },
    update: (data) => {
        return http.put(`api/v1/propostas/${data.id}`, data);
    },
    show: (id) => {
        return http.get(`api/v1/propostas/${id}`);
    },
    listTemplate: () => {
        return http.get('api/v1/modelos/listar');
    },
    createTemplate(data) {
        return http.post('api/v1/modelos', data);
    },
    updateTemplate(data, id) {
        return http.put(`api/v1/modelos/${id}`, data);
    },
    deleteTemplate(id) {
        return http.delete(`api/v1/modelos/${id}`);
    },
    alterImpress(id) {
        return http.patch(`api/v1/propostas/impressa/${id}`);
    },
    indexProposal(filter, page = 1, allotmentId = null, perpage = null, exportIn = null) {
        if (perpage === 'selecione' || !perpage) perpage = null;
        let responseType = exportIn === 'xls' ? 'blob' : '';
        return http.get('api/v1/propostas?page=' + page, {
            params: {...filter, perpage, exportIn},
            headers: {'allotment-id': allotmentId},
            responseType,
        });
    },
    reprintProposal(id) {
        return http.post(`api/v1/propostas/reimprimir/${id}`);
    },
    rejectProposal(id) {
        return http.post(`api/v1/propostas/rejeitar/${id}`);
    },
    aproveProposal(id) {
        return http.post(`api/v1/propostas/aprovar/${id}`);
    },
    solicitClickSign(id) {
        return http.post(`api/v1/propostas/assinatura/${id}`);
    },
    cancelClickSign(id) {
        return http.post(`api/v1/propostas/cancelar-assinatura/${id}`);
    },
    showDocumentClickSign(id){
        return http.get(`api/v1/propostas/visualizar-assinatura/${id}`);
    },
    viewProposalAssinedManual(id){
        return http.get(`api/v1/propostas/visualizarDocumentoAssinadoManual/${id}`, {responseType: 'blob'});
    },
    deleteProposalAssinedManual(id){
        return http.delete(`api/v1/propostas/deletarDocumentoAssinadoManual/${id}`);
    },
    getResumAssigns(day = '7', allotmentId = null)
    {
        if (allotmentId){
            return http.get('api/v1/dashboard/resumoClickSignPorCompanhia',{headers:{
                days: day, 'allotment-id': allotmentId}});
        }
        return http.get('api/v1/dashboard/resumoClickSignPorCompanhia',{headers:{days: day}});
    },
    indexAssigns(status, page = 1, allotmentId = null, term = '', perPage = null, filters = {}){
        let headers = {};
        if (allotmentId) headers['allotment-id'] = allotmentId;
        if (perPage === 'selecione' || !perPage) perPage = '';
        return http.get( `api/v1/propostas/assinatura-por-status/${status}?page=${page}&term=${term}&perpage=${perPage}`, {
            headers:{...headers},
            params:{filters},
        });
    },
    indexAssignAdmPending(page = 1, allotmentId = null, term = '', perPage = null, filters = {})
    {
        let pendingBy = '';
        let headers = {};
        if (allotmentId) headers['allotment-id'] = allotmentId;
        if (filters.pendingBy){
            pendingBy = filters.pendingBy;
            delete filters.pendingBy;
        }
        if (perPage === 'selecione' || !perPage) perPage = '';
        return http.get(`api/v1/propostas/assinatura-administracao-pendente?page=${page}&term=${term}&perpage=${perPage}`,{
            headers:{...headers},
            params:{filters, pendingBy},
        });
    },
    getResumPendingAdm(allotmentId = null) {
        let headers = {};
        if (allotmentId) headers['allotment-id'] = allotmentId;
        return http.get('api/v1/dashboard/numero-assinatura-administracao-pendente',{headers:{...headers}})
    },
    getProgressAssigns(days = null, allotmentId = null)
    {   let headersOptionals = {}
        if (allotmentId) headersOptionals['allotment-id'] = allotmentId;
        if (days) headersOptionals.days = 30;
        return http.get('api/v1/dashboard/progressoClickSignPorCompanhia',{headers:{...headersOptionals}});
    },
    watsappClickSing(signer){
        return http.post(`api/v1/propostas/send-whatsapp/clicksign/signer`, signer);
    }
}